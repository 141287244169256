.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin  infinite 500s linear 1s, App-logo-spin-in 1s linear;

    
    width: 25vmin; 
    height: 25vmin;
    object-fit: cover;
    border-radius: 10%  ;
  }
}

.App-header {
  background-color: #95aad3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(255, 255, 255, 0.712);
}

.small-text {
  text-align: center;
  font-size: calc(10px + 0.5vmin);

}

.App-link {
  color: #61dafb;
}

.input {
  color: rgb(44, 46, 47);
  background-color: antiquewhite;
  font-size: 40px;
  height: 40px;
  width: 500px;
}

@keyframes App-logo-spin {
  from {
    transform:  rotate(0deg) ;
 
    
  }
  to {
    transform:  rotate(-360deg) ;

    
    
  }
}

@keyframes App-logo-spin-in {
  from {
    transform: scale(50%) rotate(0deg) ;
 
    
  }
  to {
    transform: scale(100%) rotate(-360deg) ;

    
    
  }
}



